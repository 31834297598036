import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UNCLandingPage = () => (
  <PhysicianLandingPage
    physician="UNC"
    institution="UNC"
    referralCode="UNC"
    physicianURL="https://www.med.unc.edu/urology/"
  />
)

export default UNCLandingPage
